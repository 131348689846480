import React, { useState } from "react";

import { Link } from "gatsby";
import SEO from "../../../components/seo/seo";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import CardGeneric from "../../../components/card/card-generic";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Button from "../../../components/custom-widgets/button";
import ModalGeneric from "../../../components/custom-widgets/modal-generic";
import LoginHelpModalData from "../../../components/login/login-help-modal-data";
import Icon from "../../../components/custom-widgets/icon";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const CreditCards = () => {
  const seoTitle = "Fixed Rate Credit Cards";
  const SEOData = {
    title: seoTitle,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: seoTitle
      },
      {
        name: "description",
        content: "Learn about the WaFd Bank cash back rewards credit card. Apply Today!"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      },
      {
        property: "og:title",
        content: seoTitle
      },
      {
        property: "og:description",
        content: "Learn about the WaFd Bank cash back rewards credit card. Apply Today!"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/fixed-rate-credit-cards"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-personal-credit-card.jpg"
      }
    ]
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const loginHelpModalData = {
    handleClose,
    show,
    modalSize: "md",
    header: {
      id: "cc-sign-in-help-modal-title",
      class: "mb-0",
      text: "Sign In Help"
    },
    body: {
      class: "",
      content: [<LoginHelpModalData />]
    }
  };

  const wafdCardsBtnData = {
    id: "credit-card-sign-in-btn",
    externalLink: true,
    class: "btn-success btn-md btn-block no-max-width text-center",
    text: "Sign In",
    showIcon: false,
    url: "https://www.ezcardinfo.com/?WAFD=/",
    target: "blank",
    style: {
      minWidth: "0"
    }
  };

  const loginCardInfo = {
    cardClass: "border mt-4 mt-lg-0",
    segments: [
      {
        type: "card-body",
        class: "px-md-4",
        content: [
          <h5 className="card-title">Sign in to your WaFd bank Credit Card (eZCard)</h5>,
          <Button {...wafdCardsBtnData} />
        ]
      },
      {
        type: "card-footer",
        class: "bg-light",
        content: [
          <div className="row">
            <div className="col col-lg-9">
              First time user?{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ezcardinfo.com/?WAFD=/"
                id="wafd-ez-card-enrollment-link"
              >
                Enroll Now
              </a>
            </div>
            <div className="col-auto col-lg-3 text-nowrap">
              <a href="/#" onClick={handleShow} className="text-decoration-none" id="wafd-ez-card-help-link">
                <Icon name="question-circle" lib="far" class="mr-1" />
                Help
              </a>
            </div>
            {show && <ModalGeneric {...loginHelpModalData} />}
          </div>
        ]
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Fixed Rate Credit Cards"
    }
  ];

  const selectCreditCardDocumentsListData = [
    {
      id: "select-banking-cardholder-agreement",
      text: "Select Banking Cardholder Agreement",
      url: "/documents/credit-cards/select-banking/select-banking-cardholder-agreement.pdf"
    },
    {
      id: "select-banking-interest-rate-and-fees",
      text: "Select Banking Interest Rate and Fees",
      url: "/documents/credit-cards/select-banking/select-banking-interest-rate-and-fees.pdf"
    },
    {
      id: "visa-guide-to-benefits-traditional-tier-version",
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Traditional tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(traditional-tier-version).pdf"
    }
  ];

  const cashBackDocumentsListData = [
    {
      id: "cash-back-rewards-visa-and-visa-signature-rate",
      text: "WaFd Bank Cash Back Rewards Visa<sup>&reg;</sup> and Visa Signature<sup>&reg;</sup> Interest Rate and Fee Schedule",
      url: "/documents/credit-cards/fixed-rate/wafd-bank-cash-back-rewards-visa-signature-rate.pdf"
    },
    {
      id: "cash-back-rewards-visa-signature-cardholder-agreement",
      text: "WaFd Bank Cash Back Rewards Visa Signature<sup>&reg;</sup> Cardholder Agreement",
      url: "/documents/credit-cards/fixed-rate/wafd-bank-cash-back-rewards-visa-signature-cardholder-agreement.pdf"
    },
    {
      id: "cash-back-rewards-visa-cardholder-agreement",
      text: "WaFd Bank Cash Back Rewards Visa<sup>&reg;</sup> Cardholder Agreement",
      url: "/documents/credit-cards/fixed-rate/wafd-bank-cash-back-rewards-visa-cardholder-agreement.pdf"
    },
    {
      id: "visa-guide-to-benefits-signature-tier-version",
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Signature tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(signature-tier-version).pdf"
    },
    {
      id: "visa-guide-to-benefits-traditional-tier-version",
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Traditional tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(traditional-tier-version).pdf"
    },
    {
      id: "scorecard-cash-back-rewards-program-rules",
      text: "ScoreCard<sup>&reg;</sup> Cash Back Rewards Program Rules",
      url: "/documents/credit-cards/scorecard-cash-back-rewards-program-rules.pdf"
    }
  ];

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      {/* <HeroChevron {...heroChevronData} /> */}
      <Breadcrumb data={breadcrumbData} />
      {/* Credit Cards Login Section */}
      <section className="container pb-0">
        <div className="row align-items-center d-flex">
          <div className="col-12 col-lg-7 col-xl-8">
            <h1>WaFd Bank Fixed Rate Credit Cards</h1>
            <h3 className="mb-0">
              For fixed rate* cardholders: view your cardholder agreement, interest rate and fee schedule, and other
              program information below.
            </h3>
          </div>
          <div className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
            <CardGeneric {...loginCardInfo} />
          </div>
        </div>
        <br />
      </section>
      {/* Cash Back Credit Card Section */}
      <section className="container pt-0">
        <div className="row align-items-start">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-md-6">
                <StaticImage
                  src="../../../images/credit-cards/thumbnail-credit-signature-horizontal.jpg"
                  alt="WaFd Bank Cash Back Credit Card"
                  placeholder="blurred"
                  className="mx-auto text-center card-box-shadow"
                  quality="100"
                />

                <div className="mb-5 my-sm-4 mx-sm-2">
                  <h3>Cash Back Credit Card</h3>
                  <p>
                    Have questions? See our{" "}
                    <Link
                      to="/personal-banking/credit-cards/cash-back-credit-card-faq"
                      id="cash-back-credit-card-faq-link"
                    >
                      Credit Card FAQ
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <StaticImage
                  src="../../../images/credit-cards/thumbnail-credit-business-signature-horizontal.jpg"
                  alt="WaFd Bank Small Business Credit Card"
                  placeholder="blurred"
                  className="mx-auto text-center card-box-shadow"
                  quality="100"
                />

                <div className="mb-5 my-sm-4 mx-sm-2">
                  <h3>Small Business Cash Back Credit Card (Community Banking Credit Card)</h3>
                  <p>
                    See our{" "}
                    <Link
                      to="/business-banking/small-business-credit-card/faq"
                      id="small-business-credit-card-faq-link"
                    >
                      Small Business Credit Card FAQ
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <ul className="my-3 list-unstyled no-padding-inline">
                  {cashBackDocumentsListData.map((document, idx) => (
                    <>
                      <li key={document.id} className={idx === cashBackDocumentsListData.length - 1 && "mb-0"}>
                        <a
                          id={document.id}
                          href={document.url}
                          target="_blank"
                          className="text-decoration-none"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon className="mr-2" icon={[`fal`, `file-alt`]} />
                          <span dangerouslySetInnerHTML={{ __html: document.text }} />
                        </a>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-lg-4">
            <div className="row h-100 border-top border-lg-top-0 border-lg-left border-gray-20 mx-0 mt-2 pt-4 mt-lg-0 pt-lg-0">
              <div className="col-md-4 col-lg-12 px-0 px-md-3">
                <StaticImage
                  src="../../../images/credit-cards/thumbnail-credit-select-vertical.jpg"
                  alt="WaFd Bank Small Business Credit Card"
                  placeholder="blurred"
                  className="mx-auto text-center card-box-shadow"
                  quality="100"
                  height="350"
                />
              </div>
              <div className="col-md-8 col-lg-12">
                <div className="row align-items-start">
                  <div className="col-12 px-0 px-sm-2 px-md-3">
                    <div className="mb-4 mt-sm-4 mt-md-0 mt-lg-4">
                      <h3>Select Credit Card</h3>
                    </div>
                  </div>
                  <div className="col-12 px-md-3">
                    <ul className="my-0 list-unstyled no-padding-inline pl-0">
                      {selectCreditCardDocumentsListData.map((document, idx) => (
                        <>
                          <li
                            key={document.id}
                            className={idx === selectCreditCardDocumentsListData.length - 1 && "mb-0"}
                          >
                            <a
                              id={document.id}
                              href={document.url}
                              target="_blank"
                              className="text-decoration-none"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon className="mr-2" icon={[`fal`, `file-alt`]} />
                              <span dangerouslySetInnerHTML={{ __html: document.text }} />
                            </a>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Digital Wallet Section */}
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <StaticImage
                src="../../../images/thumbnail-digital-wallet-06092023-730.jpg"
                alt="Woman paying for dessert with digital debit card mobile pay"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2 className="text-success">Add Your Credit Card To Your Digital Wallet</h2>
              <p>
                A digital wallet allows you to safely and securely store your personal payment information virtually, in
                a single place, through an app on your phone.
              </p>
              <p>
                <Link
                  to="/personal-banking/debit-cards/digital-debit-card"
                  id="digital-debit-card-page-link"
                  className="text-decoration-none"
                >
                  Learn about Digital Wallets
                  <Icon name="arrow-right" class="ml-1" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Disclaimers */}
      <section className="container text-sm text-gray-60">
        <p id="disclaimer-apr">
          <sup>&dagger;</sup> Annual Percentage Rate (APR) The cost to borrow money expressed as a yearly percentage.
        </p>
        <p id="disclaimer-1">* For fixed rate cardholders who applied prior to 11/01/2023.</p>
        <p id="disclaimer-2">
          ** 2% cash back can be earned for gas, grocery, and pharmacy purchases for Visa Signature<sup>&reg;</sup>{" "}
          cardholders and 1% cash back earned for all qualified purchases for both Visa<sup>&reg;</sup> and Visa
          Signature<sup>&reg;</sup> cardholders.
        </p>
        <p id="disclaimer-3">
          *** Subject to credit approval. Certain terms, conditions, and exclusions apply. Please refer to your Visa
          Guide to Benefits and Cardholder Agreement for details.
        </p>
        <p>
          See the{" "}
          <Link id="wafd-privacy-notice" to="/privacy-center">
            WaFd Bank Privacy Notice
          </Link>
          .
        </p>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CreditCards;
